export const educationData = [
    {
        id: 1,
        institution: 'City University of Hong Kong',
        course: 'Bachelor of Computer Science',
        startYear: '2015',
        endYear: '2019'
    },
    // {
    //     id: 2,
    //     institution: 'Noakhali Islamia Kamil Madrasah',
    //     course: 'Higher Secondary',
    //     startYear: '2018',
    //     endYear: '2020'
    // },
    // {
    //     id: 3,
    //     institution: 'Baitus Saif Islamia Madrasah',
    //     course: 'Secondary School',
    //     startYear: '2008',
    //     endYear: '2018'
    // },
]