export const experienceData = [
    {
        id: 1,
        company: 'Niteco',
        jobtitle: 'Full Stack Developer',
        startYear: 'Jan 2021',
        endYear: 'Present'
    },
    {
        id: 2,
        company: 'Kactus',
        jobtitle: 'Front End Developer',
        startYear: 'Jul 2018',
        endYear: 'Mar 2021'
    },
    {
        id: 3,
        company: 'Webnatics',
        jobtitle: 'Junior Front End Developer',
        startYear: 'Sep 2017',
        endYear: 'Jul 2018'
    },
]